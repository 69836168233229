<template src="./EventGroupBySID.html"></template>

<script>

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import store from "@/store";
import {AUTH_REQUEST} from "@/store/actions/auth";

export default {
  name: "EventGroupBySID",
  components:{VuePhoneNumberInput},

  props:{


    // если true - то значения event/show/theater берем из роутера
    // если false, то из переданных props
    useRouter:        {type: Boolean,     default: false},

    // режим работы event не проработан
    event:            {type: Number,      default: 0},
    show:             {type: Number,      default: 0},
    theater:          {type: Number,      default: 0},
    info:             {type: String,      default: "П<b>рив</b>ет"},
    isDaily:          {type: Boolean,     default: false},            // вход на выставку без определенного часа
    noTicketMessage:  {type: String,      default: "Билетов нет"},


    // show - выбор ограничен одним названием спектакля
    // event - прям конкретное мероприятие без выбора
    // general - выбор из всей возможной афиши
    mode:             {type: String,      default: "show"},

    // показывать ли возможность выбирать другой спектакль/дату
    showPicker:       {type: Boolean,     default: false},
    canChangeShow:    {type: Boolean,     default: false},

    // только пушкинские
    pushka:           {type: Boolean,     default: false},

    // можно купить только один билет
    singleTicket:     {type: Boolean,     default: true},

  },

  data: () => ({

    props_data:{
      event_id:   0,
      theater_id: 0,
      show_id:    0,
      info:       "И тут привет",
      isDaily:    false,
      singleTicket: true,
      noTicketMessage: "Билетов нет"

    },

    // open dialog
    dialog:   false,
    // первая загрузка
    initload: true,

    disabled: true,

    events_afisha:[],
    events_loaded: false,

    selected_show: {
      info:{
        id:0,
        name: "Укажите событие"
      }
    },

    selected_date: null,

    empty_event: {
      stat:{
        free:0
      },
      types:[],
      loaded:false,
    },

    selected_event: {
      stat:{
        free:0
      },
      types:[],
      loaded:false,
    },


    system:{

      isLocalAuth: false,
      theater_id:  7,

    },

    but_text: "ОПЛАТИТЬ ПУШКИНСКОЙ КАРТОЙ",
    but_loading: false,

    order_good: false,
    order_id:0,
    bank_link:"",

    user_data:{

      payment_type:0,   // 0 - обычная, 30 - пушка

      valid: false,

      family: '',
      name: '',
      fname: '',
      phone: '',
      email: '',

      verify:{

        email:  '',
        fio:    '',
        phone:  ''

      },

      check:{
        family: false,
        name: false,
        fname: false,

        fio: false,

        phone: false,
        email: true,

        rules_museum: false,
        rules_pushka: false,

        allGood: false,
      }

    },

    isPublic:false,


    t:false,

    prices: [],


    // можно выбрать на календаре
    selectable_dates:[],
    // точки на календаре
    higlight_events: [],

    // доступные мероприятия в эту дату
    events_in_date:[],
    selected_event_id: 0,


    weekdays: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],

    singleTicketType: 0,




    //----------------------

    radioGroup:1,

    date_show: false,
    //date_current: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date_current: null,
    date_events:[],

    date_time_current: null,
    date_times:["10:00", "15:00"],

    ticket_types:['1','2'],
    toggle_exclusive: undefined,

    phone: '',

  }),

  created() {

    console.clear();
    this.props_data.singleTicket = this.singleTicket;

    //console.log(window.location.pathname);
    //console.log(this.$route);

    // берем значения из роутера
    if (this.useRouter){

      if (this.$route.params.tid) {
        this.props_data.theater_id = parseInt(this.$route.params.tid);

        //console.log(this.$route);
        //console.log(this.mode);

        let sid = 0;
        let eid = 0;

        switch (this.mode){

          case "show":
            if (this.$route.params.sid){
              sid = parseInt(this.$route.params.sid);
            }
            this.props_data.show_id = sid;
            break;

          case "event":
            if (this.$route.params.eid){
              eid = parseInt(this.$route.params.eid);
            }
            this.props_data.event_id = eid;
            break;
        }

      }
      else{
        console.log('нет театра нет входа')
        return '/none'
      }
    }
    // из props
    else{
      this.props_data.theater_id  = this.theater;
      this.props_data.event_id    = this.event;
      this.props_data.show_id     = this.show;
    }

    //this.props_data.info = this.info;

    // билет действует весь день. Нужно закрыть выбор времени
    //this.props_data.isDaily = true;
    console.log(this.props_data.theater_id);
    switch (this.props_data.theater_id){

      // самара космическая
      case 7:
        this.props_data.info =
            "<b>Для дошкольников посещение выставки бесплатное</b>" +
            "<br><br>" +
            "Уважаемые посетители, в кассе музея вы можете приобрести единый билет, позволяющий посетить несколько экспозиций."
            "<br><br>";
        break

      // детская худ галлерея
      case 12:

        //console.log(this.props_data.show_id);
        this.props_data.singleTicket = false;

        if (
          (this.props_data.show_id == 12) ||
          (this.props_data.show_id == 15) ||
          (this.props_data.show_id == 17)
        ){
          this.props_data.isDaily = true
        }


        this.props_data.info =
            "Для дошкольников (до 7 лет) посещение выставки бесплатное." +
            "<br><br>" +
            "Для групп более 10 человек экскурсионное обслуживание бесплатно (кроме групп дошкольников)." +
            "<br><br>" +
            "<a target='_blank' href='https://childgal.ru/1861-2/?et_fb=1&PageSpeed=off'>Подробнее по стоимости и условиям</a> " +
            "<br><br>" +
            "<b>График работы</b><br>" +
            "Четверг: 9:00 - 20:00<br>" +
            "Остальные дни: 9:00 - 18:00" +
            "<br><br>" +
            "Вход в музей прекращается за 30 минут до закрытия." +
            "";

        this.props_data.noTicketMessage = '<b>Онлайн билеты проданы, о наличии билетов в кассе музея можно узнать по телефону <a href="tel:+78463324398">332-43-98</a></b>';

        //console.log('!!!');

        break;

    }

    // this.props_data.info =
    //     "Для дошкольников (до 7 лет) посещение выставки бесплатное." +
    //     "<br><br>" +
    //     "Для групп более 10 человек экскурсионное обслуживание бесплатно (кроме групп дошкольников)." +
    //     "<br><br>" +
    //     "<a target='_blank' href='https://www.childgal.ru/posetitelyam'>Подробнее по стоимости и условиям</a> " +
    //     "<br><br>" +
    //     "<b>График работы</b><br>" +
    //     "Четверг: 9:00 - 20:00<br>" +
    //     "Остальные дни: 9:00 - 18:00" +
    //     "<br><br>" +
    //     "Вход в музей прекращается за 30 минут до закрытия." +
    //     "";

    this.$store.state.api.hello();

    if (this.mode === "show"){
      console.log('show')
      this.auth_public(this.props_data.theater_id , {SID:this.props_data.show_id, EID:0}, "show");
    }

    if (this.mode === "event"){
      console.log('event')
      this.auth_public(this.props_data.theater_id , {SID:0, EID:this.props_data.event_id}, "event");
    }




  },

  mounted() {
    //console.log('dialog museum payment mount');
  },

  computed:{

    getTimeHint(){

      if (this.props_data.isDaily){
        return "Билет без привязки ко времени"
      }
      else{
        return ""
      }

    },

    isExistFreeSeats(){
      return (this.selected_event.stat.free>0);

    },

    tickets(){

      let r = [];

      this.prices.forEach((count, index)=>{

        if (count>0){

          r.push({
            type:  this.selected_event.types[index].id,
            price: this.selected_event.types[index].price,
            count: count,
            sum:   this.selected_event.types[index].price * count
          })

        }

      })

      return r;

    },

    pushka_text(){

      return this.user_data.payment_type === 30 ? "pushka" : "";

    },

    total_count(){

      let t = 0;
      this.prices.forEach(count=>{
        if (count>0){
          t += parseInt(count);
        }
      })
      return t;
    },

    total_price(){

      let t = 0;
      this.prices.forEach((count, index)=>{
        if (count>0){
          t += this.selected_event.types[index].price * count;
        }
      })

      return t;
    },

    event_day(){

      if (!(Object.keys(this.selected_date).length === 0 && this.selected_date.constructor === Object)){
        return this.weekdays[this.selected_date.getDay()-1];
      }
      else{
        return "";
      }
    }

  },



  methods: {

    changeCategory(i){
      //console.log(i)

      this.prices = [0,0,0,0,0,0,0,0,0,0];
      this.$set(this.prices, i, 1);

    },

    auth_public(TID, IDS = {SID:0, EID:0}, mode = "show") {

      //console.log(TID, SID);

      const username = "api_user@zakaz.cloud";
      const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      const isAdmin = false;
      const theater_select = TID;

      //console.log('before');

      store.dispatch(AUTH_REQUEST, {username, password, theater_select, isAdmin})

          .then(() => {

            console.log(mode);

            // открываем страницу спектакля
            if (IDS.SID > 0) {
              //console.log('sid:', SID);
              this.loadGeneral();
            }

            if (IDS.EID > 0) {
              console.log('eid:', IDS.EID);
              //this.loadGeneral();
              this.onChangeTime(IDS.EID);
            }

          })
    },


    changePaymentType(isPushka=false){

      if (isPushka){
        this.user_data.payment_type = 30;
      }
      else{
        this.user_data.payment_type = 0;
      }

      this.checkUserData();

    },




    checkUserData(){

      //console.log(this.user_data.payment_type);

      this.user_data.check.family = false;
      this.user_data.check.name   = false;
      this.user_data.check.fname  = false;
      this.user_data.check.fio    = false;
      this.user_data.check.phone  = false;

      this.user_data.verify.fio = '';



      // пушка
      if (this.user_data.payment_type===30){

        //console.log('30');

        if (this.user_data.verify.phone.length==10){
          this.user_data.check.phone  = true;
        }

        this.user_data.check.family = this.user_data.family.length > 2;
        this.user_data.check.name   = this.user_data.name.length > 2;
        this.user_data.check.fname   = this.user_data.fname.length > 2;


        this.user_data.check.fio = (
            (this.user_data.check.family) &&
            (this.user_data.check.name)   &&
            (this.user_data.check.fname)
        );

        if (this.user_data.check.fio){

          this.user_data.verify.fio =
              this.user_data.family.trim() + " " +
              this.user_data.name.trim() + " " +
              this.user_data.fname.trim();
        }

      }

      // обычная карта
      else {

        this.user_data.check.family = true;
        this.user_data.check.name   = true;
        this.user_data.check.fname  = true;
        this.user_data.check.fio    = true;

        this.user_data.check.phone  = true;
        this.user_data.check.rules_pushka = true;

        this.user_data.verify.fio = '';

      }



      this.user_data.check.email = this.isEmailValid(this.user_data.email);

      //console.log(this.user_data);

      this.user_data.check.allGood = (
          (this.user_data.check.fio) &&
          (this.user_data.check.phone) &&
          (this.user_data.check.email) &&
          (this.user_data.check.rules_museum) &&
          (this.user_data.check.rules_pushka) &&
          (this.total_count>0)
      );

      //console.log(this.user_data.check.allGood);
      //console.log(this.user_data);

    },

    isFieldValid(value){

      console.log(value);

    },

    isEmailValid: function(value) {

      //console.log(value);

      const reg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      return ((value === "") && value.length>2)? "" : (reg.test(value));
    },

    isMobile() {
      return screen.width <= 760;
    },

    onDialogShowClick(){

      if (!this.disabled){
        this.dialog = true;

        this.pickEvent(0);
      }

    },





    onPaymentClick(){

      //console.log(this.user_data);
      console.log('prepare to pay');
      this.checkUserData();

      if (this.user_data.check.allGood){
        console.log('all good');

        let post_data = {

          base:{
            email:      this.user_data.email,
            total:      this.total_price,
            user:       0,
            mode:       "inet_entry",
            type:       this.pushka_text,

            fio:        this.user_data.family + " " + this.user_data.name + " " + this.user_data.fname,
            phone:      this.user_data.verify.phone,

          },
          orders:[
            {
              event:    this.selected_event.event.id,
              culture:  this.selected_event.show.culture_id,

              price:    this.total_price,
              count:    this.total_count,

              seats:    this.tickets
            }
          ]
        }

        //console.log(post_data);

        //let pr2 = this.api.addOrder(post_data);
        this.$store.state.api.addOrder(post_data)

          .then( data =>{

            console.log(data);

            if (data.data.b){

              this.order_id =   data.data.payment;
              this.bank_link =  data.data.bank;
              this.order_good = true;

              this.gotoBank();
              this.but_loading = false;
            }

          })



      }
    },


    gotoBank() {

      console.log('goto bank');
      window.location.href = this.bank_link;

    },

    resultsExample(e){

      //console.log(e);
      this.user_data.check.phone = e.isValid;

      if (e.isValid){
        this.user_data.verify.phone = e.nationalNumber;
        this.user_data.check.phone  = true;
      }
      else{
        this.user_data.verify.phone = "";
        this.user_data.verify.phone = "";
        this.user_data.check.phone  = false;
      }

      this.checkUserData();

    },

    // выбрать мероприятие по id, сработает только если оно есть в events_afisha
    // 0 = выбрать первое
    pickEvent(event_id=0){

      if (event_id===0){
        this.selected_date =  new Date(this.selected_show.data['0'].timedate);
        this.onChangeDate(this.selected_date , true);
      }
      else{
        console.log("Не готово");
      }



    },


    loadGeneral(){

      let filter = {
        status: 32
      }

      if (this.props_data.show_id>0){

        filter = {
          status: 32,
          show_id: this.props_data.show_id,
        }
      }

      this.afisha_filter(filter);

    },

    onChangeShow(value){

      //console.log(value);
      this.prepare_dates(value);



    },

    onChangeTime(value){

      //console.log(value);
      this.selected_event.loaded = false;

      //let pr2 = this.api.getEventKassa(value);
      let pr2 = this.$store.state.api.getEventKassa(value);

      pr2.then( data =>{
        console.log(data);
        this.selected_event = data;

        if (this.isExistFreeSeats){
          this.prices = [1,0,0,0,0,0,0,0,0,0];
          this.singleTicketType = 0;
        }

        if (this.mode==="event"){
          this.selected_show.info.id = data.event.show_id;
          this.selected_show.info.culture_id = data.show.culture_id;
          this.selected_show.info.name = data.show.name + " " + data.event.timedate;

          console.log(this.events_afisha);

          let  t = {
            info:{
              culture_id : data.show.culture_id
            }
          }
          this.events_afisha.push(t);

          //this.events_afisha['0'].info.culture_id = data.show.culture_id;
          this.events_loaded = true;
        }

        this.selected_event.loaded = true;

      })

    },

    getEventDate(date){

      let t = new Date(date);

      let s = "";

      if (this.props_data.isDaily){
        s = t.getDate()+' '+
            t.toLocaleString('default', { month: 'long' })+' '+
            t.getFullYear()+' ('+
            this.weekdays[t.getUTCDay()]+')';
      }
      else{
        s = date;
      }

      return s;

    },

    onChangeDate(value, pickFirstTimeEvent = true){

      //console.log(value);
      this.date_show = false;

      this.selected_event = this.empty_event;

      // let pad = function(num) { return ('00'+num).slice(-2) };
      // let sel_date = value.getUTCFullYear()         + '-' +
      //     pad(value.getMonth() + 1)  + '-' +
      //     pad(value.getDate());


      this.events_in_date = [];

      this.selected_show.data.forEach(event=>{
        if (event.timedate.slice(0,10)===value){

          this.events_in_date.push(
              {
                id:event.id,
                timedate: event.timedate.slice(11).slice(0, 5),
              }
          )
        }
      })

      //console.log(this.events_in_date);

      if (pickFirstTimeEvent){

        this.selected_event_id = this.events_in_date['0'].id;
        this.onChangeTime(this.selected_event_id);

      }

    },


    prepare_times(event_date){

      console.log(event_date);

    },


    prepare_dates(event_show_data){

      this.selected_date = null;

      //console.log('dates');
      //console.log(event_show_data);

      this.selectable_dates = [];
      this.higlight_events = [];

      event_show_data.data.forEach(event=>{

        //console.log(event);

        //this.selectable_dates.push(new Date(event.timedate))
        this.selectable_dates.push(event.timedate.substring(0,10));

        // this.higlight_events.push(
        //     {
        //       date: new Date(event.timedate),
        //       type: 'is-success'
        //     }
        // )
      })

      //console.log(this.selectable_dates, this.higlight_events);

      this.selected_date = this.selectable_dates['0'];
      this.onChangeDate(this.selectable_dates['0'], true);
      //this.$forceUpdate()

    },




    afisha_filter(filter){

      //console.log('Events');

      let target = "coming";

      if (this.props_data.isDaily){
        target = "coming_today";
      }

      this.events_loaded = false;

      const post_data = {

        global:{
          format:"raw",
          action:"find",
          module:"event"
        },

        data:{

          mode:{
            target: target,
            date_field: "timedate"
          },

          filter: filter,

          fields:{
            id:1,
            pushka:1,
            show_id:1,
            timedate:1,
            status:1,
            prices:1
          },
          param:{
            sort:'timedate',
            desc:false,
            limit:1500,
            group:'show_id',
            group_sort:'timedate'
          },
          detail:{
            show_id:{
              fields:{
                id:1,
                name:1,
                age:1,
                time:1,
                culture_id:1
              }
            }
          }
        }
      };

      //console.log(post_data);

      this.$store.state.api.findEvents(post_data)

        .then( data =>{
          //console.log(data);
          this.events_afisha = data;
          this.events_loaded = true;

          // это первая загрузка компонента - обрабатываем входные значения
          if (this.initload){

            // console.log('init');
            // console.log(this.props_data);

            if (this.props_data.show_id>0){

              let t = this.events_afisha.find(s=>{
                return s.info.id===this.props_data.show_id;
              })

              //console.log(t);

              if (typeof t != 'undefined'){
                if (t.info.id===this.props_data.show_id){
                  this.disabled = false;

                  if (!(Object.keys(t).length === 0 && t.constructor === Object)){

                    this.selected_show = t;
                    //this.$set(this, 'selected_show', t);

                    //console.log(this.selected_show);
                    this.onChangeShow(this.selected_show);
                    this.$forceUpdate();
                  }
                }
              }

            }

            this.initload = false;

          }


      })

    },





    clearDate () {
      this.date = null
    },

    loadEventsByShowID(show_id){

      console.log('Events');

      const post_data = {

        global:{
          format:"raw",
          action:"find",
          module:"event"
        },

        data:{
          filter:{
            status: 32,
            show_id: show_id,
          },
          fields:{
            id:1,
            pushka:1,
            show_id:1,
            timedate:1,
            status:1,
            prices:1
          },
          param:{
            sort:'timedate',
            desc:false,
            limit:1500,

          },
          detail:{
            show_id:{
              fields:{
                id:1,
                name:1,
                age:1,
                time:1
              }
            }
          }
        }
      };

      let pr2 = this.api.findEvents(post_data);

      pr2.then( data =>{
        //console.log(data);
        this.events_afisha = data;
        this.events_loaded = true;
      })

    },

    loadEvents(){

      console.log('Events');

      const post_data = {

        global:{
          format:"raw",
          action:"find",
          module:"event"
        },

        data:{
          filter:{
            status: 32
          },
          fields:{
            id:1,
            pushka:1,
            show_id:1,
            timedate:1,
            status:1,
            prices:1
          },
          param:{
            sort:'timedate',
            desc:false,
            limit:500,
            group:'show_id',
            group_sort:'timedate'

          },
          detail:{
            show_id:{
              fields:{
                id:1,
                name:1,
                age:1,
                time:1
              }
            }
          }
        }
      };

      let pr2 = this.api.findEvents(post_data);

      pr2.then( data =>{
        //console.log(data);
        this.events_afisha = data;
        this.events_loaded = true;
      })




    },




    ticketChange(index, value){

      //
       console.log(index, value);
      //
      // let x = value;
      //
      // // if (this.total_count > this.selected_event.stat.free-1){
      // //   x = this.prices[index];
      // // }
      //
      // x = this.prices[index];
      //
      // this.$set(this.prices, index, x);

    },


    ticketCountChange(index, isPlus = true){

      let x = this.prices[index];

      if (isPlus){
        x++;
        if (this.total_count > this.selected_event.stat.free-1) {
          // if (this.total_count > 0){
          x = this.prices[index];
          // }
        }
      }
      else{
        x--;

        if (x < 1){
          x = 0;
        }
      }

      this.$set(this.prices, index, x);

    },





    allowedDates(val){

      //console.log('!'+val+'!');
      let b = false;

      this.selectable_dates.forEach((x)=>{
        if (x == val){
          //console.log('!'+val+'!');
          b = true
        }
      })

      return b;

    }
  },

}


</script>

<style scoped>

</style>
